// ant design table的排序，搜索功能
import {trim} from "core-js/internals/string-trim";

export const tableOperationMixin = {
    data() {
        return {
            searchConditionTag: [],//搜索tag
            searchText: '',
            inputSearch: '',
            sortConditionTag: [],
            filteredInfo: null,
            filteredVisible: true,
            tableSearchList: [],
        }
    },
    methods: {
        // 搜索
        handleSearch(setSelectedKeys, selectedKeys, confirm, column, clearFilters, type) {
            var range = type === 'range'
            let time = type === 'time'
            if (range) {
                if (selectedKeys[0] !== undefined && selectedKeys[1] !== undefined) {
                    if (selectedKeys[0] > selectedKeys[1]) {
                        this.$message.error('搜索范围出错，请确保搜索的最大值大于或者等于最小值')
                        return
                    }
                    selectedKeys[0] = selectedKeys[0] + ' 至 ' + selectedKeys[1]
                } else if (selectedKeys[0] === undefined && selectedKeys[1] === undefined) {
                    // 两个都没填
                } else if (selectedKeys[0] === undefined) {
                    //只填了最大值
                    selectedKeys[0] = ' 至 ' + selectedKeys[1]
                } else if (selectedKeys[1] === undefined) {
                    //只填了最小值
                    selectedKeys[0] = selectedKeys[0] + ' 至 '
                }
            }
            if (time) {
                if (selectedKeys[0] === undefined && selectedKeys[1] === undefined) {
                    //都未填写
                } else if (selectedKeys[0] === undefined) {
                    //未填最小日期
                    selectedKeys[1] = new Date(selectedKeys[1]).toDateString().replaceAll('/', '-')
                    selectedKeys[0] = "2023-01-01" + ' 至 ' + selectedKeys[1]
                } else if (selectedKeys[1] === undefined) {
                    selectedKeys[0] = selectedKeys[0]
                    selectedKeys[0] = selectedKeys[0] + ' 至 '
                } else {
                    selectedKeys[0] = selectedKeys[0] + ' 至 ' + selectedKeys[1]
                }
            }
            let isSearchTag =  this.searchConditionTag.map(item=>item.key).includes(column.key)
            let length = this.searchConditionTag.length
            if (isSearchTag) {
                this.sortConditionTag.forEach((item,index)=>{
                    if(item.key===column.key){
                        item.value= selectedKeys[0]
                    }
                })
                let searchIndex=-1
                for (let i = 0; i < length; i++) {
                    if(this.searchConditionTag[i].key===column.key){
                        if(selectedKeys[0]){
                            this.searchConditionTag[i].value=selectedKeys[0]
                            searchIndex=i
                            break
                        }
                    }
                }
                if(searchIndex===-1){
                    this.searchConditionTag.splice(searchIndex - 1, 1)
                }
            } else {
                //搜索值不为空
                if (selectedKeys[0] !== undefined) {
                    this.searchConditionTag.push({
                        title: column.title,
                        key: column.key,
                        value: trim(selectedKeys[0]),
                        clearFilters: clearFilters
                    })
                }
            }
            confirm()
            if (!this.$refs.searchSortTag) {
                this.$refs.wMyTable.searchConditionTag = this.searchConditionTag
            }
            this.updatePageDataBySearch()
        },
        // 删除选择的
        deleteSelect(item, index) {
            if (window.location.search && this.$route && Object.keys(this.$route.query)) {
                this.$router.push(this.$route.path)
            }
            this.searchConditionTag.splice(index, 1)
            if (!this.$refs.searchSortTag) {
                this.$refs.wMyTable.searchConditionTag = this.searchConditionTag
            }
            this.updatePageDataBySearch()
        },
        // 重置
        handleReset(column, clearFilters) {
            clearFilters()
        },
        // table改变时
        changeTable(pagination, filters, sorter) {
            let sortName = ''
            this.sortConditionTag = []
            const elements = document.querySelectorAll('.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up.on, .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down.on');
            // 遍历每个匹配的元素并移除样式
            elements.forEach(element => {
                element.removeAttribute('style');
            })
            if (sorter) {
                let order = ''
                if (sorter.order) {
                    if (sorter.order === 'ascend') {
                        sortName = '升序'
                        order = 'asc'
                    } else {
                        sortName = '降序'
                        order = 'desc'
                        sorter.order = 'descend'
                    }
                    this.sortConditionTag.push({
                        order: order,
                        colName: sorter.columnKey,
                        title: sorter.column.title,
                        sortName: sortName
                    })
                    if (!this.$refs.searchSortTag) {
                        this.$refs.wMyTable.sortConditionTag = this.sortConditionTag
                    }
                    this.updatePageData()
                }
            }
        },
        // 删除排序
        deleteSort() {
            this.sortConditionTag = []
            const elements = document.querySelectorAll('.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up.on, .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down.on');
            // 遍历每个匹配的元素并添加样式
            elements.forEach(element => {
                element.style.color = '#bfbfbf'
            })
            if (!this.$refs.searchSortTag) {
                this.$refs.wMyTable.sortConditionTag = this.sortConditionTag
            }
            this.updatePageData()
        },
    }
}

