<template>
  <div style="margin-bottom: 10px">
      可选列：
      <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange" class="check_all">全选
      </el-checkbox>
      <el-checkbox-group v-model="checkedCols" @change="handleCheckedChange">
        <draggable v-model="newCols">
        <el-checkbox v-for="(col,index) in newCols" :key="index" :label="col.title" style="margin-bottom: 5px">{{ col.title }}</el-checkbox>
        </draggable>
      </el-checkbox-group>

  </div>
</template>

<script>
import draggable from 'vuedraggable'
export default {
  name: "TableCol",
  props: ['cols','tableName'],
  components:{draggable},
  data() {
    return {
      isIndeterminate: false,
      checkAll: true,
      checkedCols: [],
      newCols:[]
    }
  },
    watch: {
    cols:{
      handler: function (newVal) {
        if (newVal.length > 0) {
          let cols=JSON.parse(JSON.stringify(newVal))//最新的值
          let tableCol=localStorage.getItem(this.tableName)//获取localStorage的表格顺序
          if(tableCol&&JSON.parse(tableCol).order&&JSON.parse(tableCol).order.length>0){
            let order=JSON.parse(tableCol).order
            cols.sort((a, b) => {
              const indexA = order.indexOf(a.title);
              const indexB = order.indexOf(b.title);
              return indexA - indexB;
            })
            this.newCols=cols
          }else {
            this.newCols = cols
          }
        }
      } ,
      immediate: true
    },
      //改变表格列顺序或者初始赋值时
    newCols: {
      handler: function (newVal) {
        if (newVal.length > 0) {
          let tableCol=localStorage.getItem(this.tableName)//获取localStorage的表格顺序
          let obj={
            order:newVal.map(item => item.title),
            checked:tableCol&&JSON.parse(tableCol).checked&&JSON.parse(tableCol).checked.length>0?JSON.parse(tableCol).checked:''
          }
          localStorage.setItem(this.tableName,JSON.stringify(obj))
          if(tableCol&&JSON.parse(tableCol).checked&&JSON.parse(tableCol).checked.length>0){
            this.checkedCols=JSON.parse(tableCol).checked
          }else {
            this.checkedCols = newVal.map(item => item.title)
          }
        }
      },
      immediate: true
    },
    checkedCols:{
      handler:function (newVal, oldVal) {
        if(newVal){
            let obj={
              checked:newVal,
              order:this.newCols.map(item => item.title)
            }
          localStorage.setItem(this.tableName,JSON.stringify(obj))
          this.isIndeterminate = newVal.length!==this.cols.length;
          this.submits()
        }
      },
      immediate:true
    },
  },
  methods: {
    //勾选多选
    handleCheckAllChange(val) {
      this.checkedCols = val ? this.newCols.map(item => item.title) : [];
      this.isIndeterminate = false;
      this.checkAll=val
    },
    //勾选
    handleCheckedChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.newCols.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.newCols.length;
    },
    submits() {
      if (this.checkedCols.length ===0) {
        this.$message.info('全不勾选时，列表默认全展示')
        return
      }
      this.$emit('submit', this.newCols,this.checkedCols)
    }
  }
}
</script>

<style scoped>
.check_all{
  margin-left: 5px;
  margin-bottom: 5px
}
</style>
