<template>
<div>
  <div>
    <p>注：点击表头图标<i class="el-icon-d-caret" style="color: #b4b5b6"></i>进行排序；点击图标<a-icon style="font-size: 14px;color: #a0a0a1;margin: 0 3px" type="filter"  />进行搜索
      <span v-if="colVisible">；勾选可选列的内容控制表格列的展示；拖拽移动可选列的内容调整表格列的顺序</span>
    </p>
  </div>
</div>
</template>

<script>
export default {
  props:['colVisible'],
  name: "TableHeadNote"
}
</script>

<style scoped>
p{
  font-size: 15px;
  color: #5c5e5e;
}
</style>
