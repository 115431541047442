<template>
  <div style="padding: 30px">
    <div class="transTable">
      <div class="tableLeft">
        <el-table :row-style="{height:'20px'}" :cell-style="{padding:'0px'}" ref="myLeftTable" :data="leftData"
                  max-height="400" border
                  @selection-change="handleLeftContentSelectionChange"
                  @row-click="checkLeftRow">
          <el-table-column
            type="selection"
            width="40">
          </el-table-column>
          <el-table-column :label="leftTitle+'('+checkLeftData.length+'/'+leftData.length+')'">
            <template slot-scope="scope">
              <span style="cursor: pointer">{{ scope.row.content }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="tableCenter">
        <el-button type="primary" icon="el-icon-arrow-right" title="右移"
                   :disabled="(!checkLeftData || checkLeftData.length === 0)"
                   @click="toRight"></el-button>
        <el-button type="primary" icon="el-icon-arrow-left" style="margin-left: 0px!important;margin-top: 12px"
                   :disabled="(!prepareToLeft || prepareToLeft.length === 0)" title="选中部分左移"
                   @click="toLeft"></el-button>
        <el-button type="primary" icon="el-icon-d-arrow-left" style="margin-left: 0px!important;margin-top: 12px"
                   title="全部左移"
                   :disabled="rightData.length===0"
                   @click="toLeftAll"></el-button>
      </div>
      <div class="tableRight">
        <el-table :row-style="{height:'20px'}" :cell-style="{padding:'0px'}" ref="myRightTable"
                  :data="isGroup?rightData:changeRightData" max-height="400" border
                  @selection-change="handleRightGroupSelectionChange"
                  @row-click="checkRightRow">
          <el-table-column
            type="selection"
            width="40">
          </el-table-column>
          <el-table-column>
            <template slot="header" slot-scope="scope">
              <div style="display: flex;justify-content: space-between">
                <span> {{ rightTitle + '(' + rightData.length + ')' }}</span>
              </div>
            </template>
            <template slot-scope="scope">
              <div class="sample-group-item" v-if="isGroup">
                <div class="sample-group-item-contents">
                  <el-checkbox-group v-model="groupMove" @change="checkRightContent(scope.row)">
                    <el-checkbox v-for="(sampleName,index) in scope.row.contents" :label="sampleName"
                                 :key="index+'sample'"></el-checkbox>
                  </el-checkbox-group>
                </div>
                <el-input style="min-width: 250px" v-model.trim="scope.row.groupName" placeholder="请输入组名"></el-input>
              </div>
              <span v-else style="cursor: pointer">{{ scope.row.content }}</span>
            </template>
          </el-table-column>
        </el-table>
        <span style="font-size: 13px;color: #b6b6b6;margin-top: 20px">{{ notice }}</span>
      </div>
    </div>
    <div style="margin-top: 40px;display: flex;justify-content: flex-end">
      <el-button @click="handleCancel">{{cancelText}}</el-button>
      <el-button type="primary" @click="handleConfirm">{{saveText}}</el-button>
    </div>
  </div>
</template>
<script>
import {isSameArr} from "./utils/index";

export default {
  name: "WTransform",
  props: {
    leftDatas: {
      // 控制是否显示 Dialog
      type: Array,
      default: [],
    },
    rightDatas: {
      type: Array,
      default: [],
    },
    leftTitle: {
      type: String,
      default: "左边",
    },
    rightTitle: {
      type: String,
      default: "右边",
    },
    notice: {
      type: String,
      default: "",
    },
    isGroup: {
      type: Boolean,
      default: false,
    },
    saveText: {
      type: String,
      default: "确定",
    },
    cancelText: {
      type: String,
      default: "取消",
    },
  },
  data() {
    return {
      title: '输入分组',
      leftData: [],
      rightData: [],
      // {"contents": ["FC-POST-05", "FC-PRE-18"], "groupName": ""}
      dialogVisible: true,
      checkLeftData: [],
      changeRightData: [],
      groupMove: [],//右边整组移动
      prepareToLeft:[]
    }
  },
  created() {
    this.leftData = this.leftDatas.map(item => {
        return {
          'content': item
        }
      }
    )
    if (!this.isGroup) {
      this.rightData=[{
        groupName:'',
        contents:JSON.parse(JSON.stringify(this.rightDatas))
      }]
    } else {
      this.rightData =[]
      this.rightData =JSON.parse(JSON.stringify(this.rightDatas))
    }
  },
  watch: {
    rightData: {
      immediate: true,
      handler(newValue, oldValue) {
        let changeRightData = []
        this.rightData.map(item => {
          item.contents.map(content => {
            changeRightData.push({
              content: content
            })
          })
        })
        this.changeRightData = changeRightData
      }
    },
  },
  methods: {
    //点击右移按钮
    toRight() {
      this.rightData.push({
        contents: this.checkLeftData,
        groupName: ''
      })
      this.leftData = this.leftData.filter(item => this.checkLeftData.indexOf(item.content) < 0)
      this.checkLeftData = []
    },
    //点击左移按钮
    toLeft() {
      this.prepareToLeft.forEach(item => {
        this.leftData.push({
          content: item
        })
      })
      this.rightData = this.rightData.map(originSample => {
        return {
          ...originSample,
          contents: originSample.contents.filter(content => {
            return this.prepareToLeft.indexOf(content) < 0;
          })
        }
      }).filter(obj => obj.contents.length > 0)
      this.groupMove = []
      this.prepareToLeft = []
    },
    //点击全部左移按钮
    toLeftAll() {
      this.rightData.forEach(item => {
        item.contents.forEach(inItem=>{
          this.leftData.push({
            content: inItem
          })
        })

      })
      this.rightData = []
      this.prepareToLeft = []
    },
//选择右边这边每组里面的细分的勾选框
    checkRightContent(row) {
        let rowSample = row.contents
        let checkSample = this.groupMove
        let flag = isSameArr(rowSample, checkSample)
        if (flag) {
          this.$refs.myRightTable.toggleRowSelection(row, true)
        } else {
          this.$refs.myRightTable.toggleRowSelection(row, false)
        }
        this.prepareToLeft = this.groupMove
    },
    //点击右边的勾选框（整行，包括一个组里面的细分）
    handleRightGroupSelectionChange(prepareToLeft) {
      if(prepareToLeft.length>0){
        let contentsArr = []
        if (this.isGroup) {
          prepareToLeft.forEach(item => {
            contentsArr.push(...item.contents)
          })
        } else {
          prepareToLeft.map(item => {
            contentsArr.push(item.content)
          })
        }
        this.prepareToLeft = contentsArr
        this.groupMove = contentsArr
      }
    },
    //点击左边的勾选框
    handleLeftContentSelectionChange(checkedSample) {
      var contents = []
      checkedSample.forEach(item => {
        contents.push(item.content)
      })
      this.checkLeftData = contents
    },
    //控制右边表格框的勾选（即按行实现整行的勾选）
    checkRightRow(row, col, event) {
      if(!this.isGroup){
        row.flag = !row.flag
        this.$refs.myRightTable.toggleRowSelection(row, row.flag)
      }
    },
    //控制左边的表格框的勾选（即按行实现整行的勾选）
    checkLeftRow(row, col, event) {
      row.flag = !row.flag
      this.$refs.myLeftTable.toggleRowSelection(row, row.flag)
    },
    handleConfirm() {
      let result=[]
      if(this.isGroup){
        result=this.rightData
      }else {
        this.changeRightData.map(item=>{
          result.push(item.content)
        })
      }
      // let result = this.isGroup ? this.rightData : this.changeRightData
      this.$emit('handleTrans', result, this.isGroup)
    },
    handleCancel() {
      this.$emit('handleTransCancel', this.isGroup)

    }
  }
}
</script>

<style  scoped>
.tableCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto 25px;
  justify-content: center;

}

::v-deep .div-sample-group .cell {
  padding: 0px;
}

.tableRight {
  min-width: 290px;
  margin-left: 20px;

}

.tableLeft {
  min-width: 200px;
}

.sample-group-item {
  display: flex;
  align-items: center;
}

.sample-group-item-contents div {
  width: 150px;
  padding: 0 10px;
  border: 1px solid #ebeef5;
}

.transTable {
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow-x: auto;
  /*padding-bottom: 20px;*/
  gap: 30px;
  /*padding: 30px;*/
  /*max-height: 400px;*/

}

::v-deep .el-checkbox {
  margin-bottom: auto !important;
}

::v-deep.el-table__body td {
  white-space: nowrap;
}

::v-deep.el-table__body-wrapper {
  max-height: 300px;
}
.search-container {
  position: absolute;
  top: 100%;
  left: 0;
  z-index:3011;
  background-color: #ffffff;
  padding: 10px;
  border: 1px solid #cccccc;
}
</style>
