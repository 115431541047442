<template>
  <div>
    <div style="width:100%;">
      <!--      可选列-->
      <table-col v-if="tableColCheck&&tableColCheck.isShow" :cols="columns" @submit="changeTableCol"
                 :table-name="tableColCheck.name"></table-col>
      <slot name="otherCon">
        <div>
        </div>
      </slot>
      <el-card>
        <!--        搜索排序tag-->
        <search-sort-tag ref="searchSortTag" :sortConditionTag="sortConditionTag"
                         :searchConditionTag="searchConditionTag" @deleteSelect="deleteSelect"
                         @deleteSort="deleteSort"></search-sort-tag>
        <a-config-provider :locale="locale">

          <a-table :data-source="tableData" :columns="columnsData" :row-selection="isShowRowSelection?rowSelection:null"
                   :rowKey="(record) => record.id!==undefined?record.id:record.projectsRow.projectId"
                   bordered
                   @change="changeTable" :pagination="false"
                   ref="table"
                   :getPopupContainer="(triggerNode) => triggerNode.parentNode">
            <template v-for="col in columnsData" :slot="col.scopedSlots?col.scopedSlots.customRender:''"
                      slot-scope="text, record">
              <slot :name="col.scopedSlots?col.scopedSlots.customRender:''" v-bind="{text,record}">
              </slot>
            </template>
            <template name="customerColStaOpera">

            </template>
            <div slot="filterDropdown" slot-scope="{ setSelectedKeys, selectedKeys, confirm, column,clearFilters }"
                 style="padding: 8px">
              <a-input v-ant-ref="c => (searchInput = c)"
                       :placeholder="'搜索'+column.title"
                       v-model="selectedKeys[0]"
                       style="width: 188px; margin-bottom: 8px; display: block;"
                       @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                       @pressEnter="() => handleSearch(setSelectedKeys,selectedKeys, confirm, column,clearFilters)"/>
              <a-button type="primary" icon="search"
                        size="small"
                        style="width: 90px; margin-right: 8px"
                        @click="() => handleSearch(setSelectedKeys,selectedKeys, confirm, column,clearFilters)">搜索
              </a-button>
              <a-button size="small" style="width: 90px" @click="() => handleReset( column,clearFilters)">取消
              </a-button>
            </div>
            <!--            大小范围搜索-->
            <div slot="filterDropdownNum" slot-scope="{ setSelectedKeys, selectedKeys, confirm, column,clearFilters }"
                 style="padding: 8px;width: 220px">
              <div style="display: flex;align-items: center;margin-bottom: 20px;">
                <a-input-number v-model="selectedKeys[0]" :min="1" :max="100000" placeholder="最小值"
                                @pressEnter="() => handleSearch(setSelectedKeys,selectedKeys, confirm, column,clearFilters,'range')"/>
                <span>----</span>
                <a-input-number v-model="selectedKeys[1]" :min="1" :max="100000" placeholder="最大值"
                                @pressEnter="() => handleSearch(setSelectedKeys,selectedKeys, confirm, column,clearFilters,'range')"/>
              </div>
              <div style="text-align: center">
                <a-button type="primary" icon="search"
                          size="small"
                          style="width: 90px; margin-right: 8px"
                          @click="() => handleSearch(setSelectedKeys,selectedKeys, confirm, column,clearFilters,'range')">
                  搜索
                </a-button>
                <a-button size="small" style="width: 90px" @click="() => handleReset( column,clearFilters)">取消
                </a-button>
              </div>
            </div>
            <!--                        //是否-->
            <div slot="filterDropdownBool"
                 slot-scope="{ setSelectedKeys, selectedKeys, confirm, column,clearFilters }"
                 style="padding: 8px">
              <div style="padding: 0px 15px;">
                <el-radio-group v-model="selectedKeys[0]">
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-radio label="是">是</el-radio>
                    </el-col>
                    <el-col :span="12">
                      <el-radio label="否">否</el-radio>
                    </el-col>
                  </el-row>
                </el-radio-group>
              </div>
              <div style="text-align: center;margin-top:5px">
                <a-button type="primary" icon="search"
                          size="small"
                          style="width: 90px; margin-right: 8px"
                          @click="() => handleSearch(setSelectedKeys,selectedKeys, confirm, column,clearFilters)">
                  搜索
                </a-button>
                <a-button size="small" style="width: 90px" @click="() => handleReset( column,clearFilters)">取消
                </a-button>
              </div>
            </div>
            <!--            //足够/不够-->
            <div slot="filterDropdownEnough"
                 slot-scope="{ setSelectedKeys, selectedKeys, confirm, column,clearFilters }"
                 style="padding: 8px">
              <div style="padding: 0px 15px;">
                <el-radio-group v-model="selectedKeys[0]">
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-radio label="足够">足够</el-radio>
                    </el-col>
                    <el-col :span="12">
                      <el-radio label="不够">不够</el-radio>
                    </el-col>
                  </el-row>
                </el-radio-group>
              </div>
              <div style="text-align: center;margin-top:5px">
                <a-button type="primary" icon="search"
                          size="small"
                          style="width: 90px; margin-right: 8px"
                          @click="() => handleSearch(setSelectedKeys,selectedKeys, confirm, column,clearFilters)">
                  搜索
                </a-button>
                <a-button size="small" style="width: 90px" @click="() => handleReset( column,clearFilters)">取消
                </a-button>
              </div>
            </div>
            <div slot="filterDropdownProjectStatus"
                 slot-scope="{ setSelectedKeys, selectedKeys, confirm, column,clearFilters }"
                 style="padding: 10px; position: relative; z-index: 9999 !important;width:280px">
              <div style="padding: 0px 15px">
                <el-radio-group v-model="selectedKeys[0]">
                  <el-row :gutter="18">
                    <el-col :span="12">
                      <el-radio label="已创建">已创建</el-radio>
                    </el-col>
                    <el-col :span="12">
                      <el-radio label="已上传采集表">已上传采集表</el-radio>
                    </el-col>
                  </el-row>
                  <el-row :gutter="18">
                    <el-col :span="12">
                      <el-radio label="裸测">裸测</el-radio>
                    </el-col>
                    <el-col :span="12">
                      <el-radio label="已选择分析人员">已选择分析人员</el-radio>
                    </el-col>
                  </el-row>
                  <el-row :gutter="18">
                    <el-col :span="12">
                      <el-radio label="已完成分析">已完成分析</el-radio>
                    </el-col>
                    <el-col :span="12">
                      <el-radio label="已完结">已完结</el-radio>
                    </el-col>
                  </el-row>
                </el-radio-group>
              </div>
              <div style="display: flex;padding: 15px">
                <a-button
                    type="primary"
                    icon="search"
                    size="small"
                    style="width: 90px; margin-right: 8px"
                    @click="() => handleSearch(setSelectedKeys,selectedKeys, confirm, column,clearFilters)">搜索
                </a-button>
                <a-button size="small" style="width: 90px" @click="() => handleReset( column,clearFilters)">取消
                </a-button>
              </div>
            </div>
            <!--            时间-->
            <div slot="filterDropdownTime" slot-scope="{ setSelectedKeys, selectedKeys, confirm, column,clearFilters }"
                 style="padding: 8px">
              <div>
                <a-date-picker
                    v-model="selectedKeys[0]"
                    value-format="yyyy-MM-DD"
                    placeholder="起始时间：xx/xx/xx/"
                    style="width: 188px; margin-bottom: 8px; display: block;"
                    @pressEnter="() =>  handleSearch(setSelectedKeys,selectedKeys, confirm, column,clearFilters,'time')"/>
                <a-date-picker
                    v-model="selectedKeys[1]"
                    value-format="yyyy-MM-DD"
                    placeholder="结束时间：xx/xx/xx/"
                    style="width: 188px; margin-bottom: 8px; display: block;"
                    @pressEnter="() =>  handleSearch(setSelectedKeys,selectedKeys, confirm, column,clearFilters,'time')"/>
              </div>
              <div style="text-align: center">
                <a-button type="primary" icon="search"
                          size="small"
                          style="width: 90px; margin-right: 8px"
                          @click="() => handleSearch(setSelectedKeys,selectedKeys, confirm, column,clearFilters,'time')">
                  搜索
                </a-button>
                <a-button size="small" style="width: 90px" @click="() => handleReset( column,clearFilters)">取消
                </a-button>
              </div>
            </div>
            <div v-for="item in filterDropdownCustomer" :slot="item"
                 slot-scope="{ setSelectedKeys, selectedKeys, confirm, column,clearFilters }">
              <slot :name="item" v-bind="{ setSelectedKeys, selectedKeys, confirm, column,clearFilters }">{{ item }}
              </slot>
            </div>
            <a-tooltip placement="topLeft" title="搜索" slot="filterIcon" slot-scope="filtered">
              <a-icon type="filter" :style="{ color: filtered ? '#108ee9' : undefined,title:'搜索'}"/>
            </a-tooltip>
          </a-table>
        </a-config-provider>
        <!--        分页-->
        <pagination v-if="isPagination" :child-msg="page" @callFather="handlePagePagination"></pagination>
      </el-card>
    </div>

  </div>
</template>

<script>
import Pagination from "./Pagination";
import {tableOperationMixin} from "../mixins/table/tableOperation";
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
import SearchSortTag from "./SearchSortTag";
import TableHeadNote from "./TableHeadNote";
import TableCol from "./TableCol";

export default {
  name: "WTable",
  components: {TableCol, Pagination, SearchSortTag, TableHeadNote},
  props: ['tableColCheck', 'tableData', 'columns', 'isPagination', 'page', 'initialConditions', 'isShowRowSelection'],
  mixins: [tableOperationMixin],
  data() {
    return {
      locale: zhCN,
      title: '添加',
      searchConditionTag: [],//搜索tag
      sortConditionTag: [],
      columnsChange: [],
      filterDropdownDefault: ['filterDropdown', 'filterDropdownBool', 'filterDropdownNum', 'filterDropdownTime', 'filterDropdownEnough', 'filterDropdownProjectStatus']
    }
  },
  watch: {
    initialConditions: {
      handler: function (val) {
        if (val && Object.keys(val).length > 0) {
          this.searchConditionTag = val.searchConditionTag ? val.searchConditionTag : []
          this.sortConditionTag = val.sortConditionTag ? val.sortConditionTag : []
        }
      },
      immediate: true
    },
  },
  computed: {
    //表格的选择框
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          this.$emit('rowSelection', selectedRows)
        },
      };
    },
    filterDropdownCustomer() {
      return [...new Set(this.columns.filter(item => {
        return !!(item.scopedSlots && item.scopedSlots.filterDropdown);
      }).map(item => item.scopedSlots.filterDropdown))].filter(item => !this.filterDropdownDefault.includes(item))
    },
    columnsData() {
      return this.columnsChange.length === 0 ? this.columns : this.columnsChange
    }
  },
  methods: {
    //换页
    handlePagePagination(page) {
      this.$emit('pageChange', page)
    },
    //更改可选列
    changeTableCol(newCols, colTitle) {
      this.columnsChange = newCols.filter(item => colTitle.some(tile => item.title === tile))
    },
    updatePageDataBySearch() {
      this.$emit('getTableSearchSortCon', this.searchConditionTag, this.sortConditionTag,this.searchCondition)
    },
    updatePageData() {
      this.$emit('getTableSearchSortCon', this.searchConditionTag, this.sortConditionTag,this.searchCondition)
    },
  }
}
</script>

<style scoped>
::v-deep .ant-table-empty .ant-table-body {
  overflow-x: visible !important;
  overflow-y: visible !important;
}

.el-row {

}
</style>
