<template>
  <div v-if="scope.text.length>=2">
    <div>
            <span>{{scope.text[0] }}
              <el-tooltip content="折叠" v-if="showToolTip[scope.index]">
                <i class="el-icon-caret-top iconMore" @click="openMore(scope.index)"></i>
              </el-tooltip>
              <el-tooltip content="展开更多" v-if="!showToolTip[scope.index]">
                <i class="el-icon-caret-bottom iconMore" @click="openMore(scope.index)"></i>
              </el-tooltip>
            </span>
      <div v-for="(con,index1) in scope.text" :key="index1" v-if="showToolTip[scope.index]">
        <div v-if="index1!==0">{{ con }}</div>
      </div>
    </div>
  </div>
  <div v-else>
    {{ scope.text[0] }}
  </div>
</template>

<script>
export default {
  name: "WExFoldTableCell",
  props:['scope',],
  data(){
    return{
      showToolTip:[],
    }
  },
  methods:{
    openMore(index){
      this.$set(this.showToolTip, index, !this.showToolTip[index]);
    }
  }
}
</script>

<style scoped>
.iconMore {
  color: #4eaad4;
  cursor: pointer;
}

</style>
