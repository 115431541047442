import MyCustomerTable from "./myCustomerTable/index";
import ExFoldTableCell from "./exFoldTableCell/index";
import Address from "./address/index";
import Upload from "./upload/index";
import Dialog from "./dialog/index";
import Transform from "./transform/index";
// import loadingDialog from "./loadingDialog/LoadingDialog";
// import pictureLoading from "./pictureLoading/PictureLoading";
import ElementUi from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
const install = (Vue, options) => {
    if (install.installed) return;
    install.installed = true;

    // 注册自定义表格组件
    Vue.component(MyCustomerTable.name, MyCustomerTable);
    Vue.component(ExFoldTableCell.name, ExFoldTableCell);
    Vue.component(Address.name, Address);
    Vue.component(Upload.name, Upload);
    Vue.component(Dialog.name, Dialog);
    Vue.component(Transform.name, Transform);

    // 注册 ElementUI 组件
    for (let componentKey in ElementUi) {
        if(componentKey.includes('Message')){
            Vue.prototype.$messageUi = ElementUi[componentKey];
        }else if(componentKey.includes('Notification')){
            Vue.prototype.$notify = ElementUi[componentKey];
        }else if(componentKey.includes('MessageBox')){
            Vue.prototype.$msgbox = ElementUi[componentKey];
        }
        else {
            Vue.use(ElementUi[componentKey]);
        }

    }

    // 注册 Ant Design Vue 组件
    for (let componentKey in Antd) {
        Vue.use(Antd[componentKey]);
    }
};

export default {
    install
};
