import {addressData} from "./addressData";
//地址
export function formatData(data) {
    var result = [];
    for (var key in data) {
        result.push({
            value: key
        })
    }
    return result
}
//街道
export function formatDistrictData(data) {
    var result = [];
    for (var i = 0; i < data.length; i++) {
        result.push({
            value: data[i]
        })
    }
    return result
}

//解析地址
export function resolutAddress(address) {
    var province, city, district,detail;
    for (var prov in addressData) {
        if (address.indexOf(prov) !== -1) {
            province = prov;
            var cities = addressData[prov];
            for (var cty in cities) {
                if (address.indexOf(cty) !== -1) {
                    city = cty;
                    var districts = cities[cty];
                    for (var dist of districts) {
                        if (address.indexOf(dist) !== -1) {
                            district = dist;
                            detail = address.replace(prov, '')
                                            .replace(cty, '')
                                            .replace(dist, '');
                            break
                        }
                    }
                    break
                }
            }
            break
        }
    }
    return  {
        province: province,
        city: city,
        district: district,
        detail:detail
    }

}
