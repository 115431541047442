<template>
  <div class="w_search_sort_div">
    <div v-if="sortConditionTag.length" class="w_sort_div">排序条件：
      <el-tag v-for="(item,index) of sortConditionTag"
              :key="index"
              closable
              @close="deleteSort(item,index)">{{ item.title }}：{{ item.sortName }}
      </el-tag>
    </div>
    <div v-if="searchConditionTag.length" class="w_search_div">筛选条件：
      <el-tag class="w_search_tag"
              v-for="(item,index) of searchConditionTag"
              :key="index"
              closable
              @close="deleteSelect(item,index)">{{ item.title }}：{{ item.value }}
      </el-tag>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchSortTag",
  props:['sortConditionTag','searchConditionTag'],
  methods:{
    deleteSelect(item,index){
      this.$emit('deleteSelect',item,index)

    },
    deleteSort(){
      this.$emit('deleteSort')

    }
  }
}
</script>

<style scoped>
.w_search_sort_div{
  display: flex;
  flex-direction: column;
}
.w_search_div{
  margin-bottom: 20px;
  text-align: left
}
.w_sort_div{
  margin-bottom: 20px;
  text-align: left
}
.w_search_tag{
  margin-right: 10px;
  /*margin-bottom: 20px*/
}
</style>
