<template>
  <div>
    <div v-if="fileInfo.style==='someFile'">
      <el-upload
          :accept="fileInfo.format.join(',')"
          class="upload-demo"
          :on-change="handleFileChangeSome"
          :auto-upload="false"
          :multiple="true"
          ref="myUpload"
          :limit="fileInfo.maxFileNum"
          :on-exceed="handleExceed"
          :before-remove="beforeRemove"
          action="#"
          :file-list="fileList">
        <el-button type="primary" class="set-el-button">选择文件</el-button>
      </el-upload>
    </div>
    <div v-else-if="fileInfo.style==='dragFile'" style="text-align: center">
      <el-upload
          ref="upload"
          :accept="fileInfo.format.join(',')"
          action
          :limit=1
          :on-change="fileChange"
          :on-exceed="handleExceed"
          :file-list="fileList"
          :auto-upload="false"
          drag>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip text-center" slot="tip" style="flex: none;text-align: center;font-size: 14px">
          <div class="el-upload__tip" slot="tip"></div>
          <span v-if="fileInfo.format">仅允许导入{{ fileInfo.format.join('、') }}格式文件。</span>
          <span v-if="fileInfo.size">不限制文件类型，但文件大小需小于{{ fileInfo.size }}M。</span>
          <el-link type="primary" :href="fileInfo.url" :download="fileInfo.url"
                   target="_blank" style="font-size:12px;vertical-align: baseline;">下载模板
          </el-link>
        </div>
      </el-upload>
    </div>
    <div v-else style="width: 100%;" class="singleFileDiv">
      <div style="width: 100%">
        <div style="display: flex;gap: 20px">
          <div class="show-file-name" >{{fileName ? fileName : fileInfo.inputPlaceholder ? fileInfo.inputPlaceholder : '请上传文件'}}</div>
          <div style="white-space: nowrap;margin-right: 30px;line-height: 22px;margin-top: 10px">
          <el-link type="primary" :href="fileInfo.url" target="_blank" :download="fileInfo.url"
                   style="font-size:12px;vertical-align: baseline;">
            示例文件
          </el-link>
          </div>
          <el-upload
              :accept="fileInfo.format.join(',')"
              class="upload-simple"
              :auto-upload="false"
              :limit=1
              :on-change="fileChange"
              :on-exceed="handleExceed"
              ref="upload"
              :show-file-list="false"
              action="#">
            <slot>
              <el-button type="primary" slot="trigger" class="set-el-button">选取文件</el-button>
            </slot>
          </el-upload>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {Message, MessageBox} from "element-ui";

export default {
  name: "WUpload",
  data() {
    return {
      fileName: '',
      fileList: [],
    }
  },
  // fileInfo:{
  //   style:'',//文件个数，单个、多、拖拽
  //   format:'',//文件格式
  //   exampleName:'',//示例文件
  //   url:'',//文件所在的路径
  //   size:''//文件大小,
  //   maxFileNum:''//允许上传的最大文件数
  // }
  props: ['fileInfo'],
  methods: {
    async handleFileChangeSome(file, fileList) {
      file.status = 'success';
      this.fileList = fileList
      this.checkRepeatFile(file, fileList)
    },
    // 读取文件前端
    async fileChange(file, fileList,isExc) {
      if (file) {
        if (this.checkUploadFile(file, fileList)){
          if(isExc){
            this.$emit('submitFile',file)
          }else {
            this.$emit('submitFile',file.raw)
          }
        }else {
          this.$refs.upload.clearFiles()
        }
      } else {
        this.$refs.upload.clearFiles()
      }
      this.fileName=file.name
      this.fileList = fileList.slice(-3);
    },
    // 多文件时检查重复文件
    checkRepeatFile(file, fileList) {
      if (this.fileInfo.style === 'someFile') {
        //多文件
        let num = 0
        let tempFileList = []
        let returnFileList = []
        let returnFileListName = []
        fileList.map(file => {
          let isNormal = this.checkUploadFile(file)
          if (isNormal) {
            this.fileName = file.name
            num++
            returnFileList.push(file.raw)
            returnFileListName.push(file.name)
            let fileListSet = new Set(returnFileListName)
            if (fileListSet.size !== returnFileListName.length) {
              Message.error("上传失败，该文件名" + file.name + "已存在")
              returnFileList.pop()
              returnFileListName.pop()
              this.$refs.myUpload.abort(file)
            } else {
              tempFileList.push(file)
              this.fileList = tempFileList
            }
          } else {
            this.$refs.myUpload.clearFiles()
          }
        })
        if (num === fileList.length) {
          this.$emit('submitFile', returnFileList)
        }
      }
    },
    // 移除文件
    beforeRemove(file, fileList) {
      return MessageBox.confirm(`确定移除 ${file.name}？`, {
        type: 'warning'
      });
    },
    // 超过最大上传数
    handleExceed(files, fileList) {
      if(this.fileInfo.maxFileNum){
        Message.warning(`当前限制选择 ${this.fileInfo.maxFileNum}个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
      }else {
        this.$refs.upload.clearFiles()
        let fileLists=[files[0]]
        this.fileChange(files[0],fileLists,'ex')
      }
    },
    // 检查文件
    checkUploadFile(file) {
      if (!file) {
        return false
      }
      let fileSuffix = file.name.substr(file.name.lastIndexOf('.'))
      let typeNormal = this.fileInfo.format.length===0 || this.fileInfo.format.includes(fileSuffix)
      if (!typeNormal) {
        Message.error('文件类型不匹配,仅支持' + this.fileInfo.format.join(',') + '格式的文件')
      }
      const isLtM = !this.fileInfo.size || file.size / 1024 / 1024 <= this.fileInfo.size
      if (!isLtM) {
        Message.error('上传文件大小不能超过' + this.fileInfo.size + 'M')
      }
      return typeNormal && isLtM
    },
  }
}
</script>

<style scoped>
.show-file-name {
  border: 1px solid #d9d9db;
  border-radius: 2px;
  padding: 0 10px;
  height: 40px;
  line-height: 40px;
  width: 80%;
  max-width: 650px;
}

.upload-simple {
  display: inline-block;
  margin-left: 20px;
}

.set-el-button {
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.singleFileDiv{

}
::v-deep .el-upload el-upload--text{
  text-align: center!important;
}
</style>
